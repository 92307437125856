import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class UtilService {
    recieveTenantId = new Subject<string>();

    setTenantId(tenantId: string) {
        this.recieveTenantId.next(tenantId);
    }
}