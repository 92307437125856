import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: ':tenantId/incomplete-picking', loadChildren: () => import('./incomplete-picking/incomplete-picking.module').then(m => m.IncompletePickingModule) },
  { path: ':tenantId/rescheduled-by-picking', loadChildren: () => import('./rescheduled-by-picking/rescheduled-by-picking.module').then(m => m.RescheduledByPickingModule) }
];

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
