import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { Subject } from "rxjs";

@Injectable()
export class IncompletePickingService {
    dataSent = new Subject<boolean>();

    constructor(private http: HttpClient) {
    }

    validateOrder(token: string, processGroup: string, data: {}) {
        return this.http.post<any>(
            `${environment.incompletePickingApiUrl}/transaction/validate/${processGroup}/${token}`,
            data,
            {
                headers: new HttpHeaders(
                    {
                        'Authorization': environment.incompletePickingApiAuth
                    }
                ),
                responseType: 'json'
            }
        );
    }

    processOrder(token: string, processName: string, data: {}) {
        return this.http.post(
            `${environment.incompletePickingApiUrl}/transaction/process/${processName}/${token}`,
            data,
            {
                headers: new HttpHeaders(
                    {
                        'Authorization': environment.incompletePickingApiAuth
                    }
                ),
                responseType: 'json'
            }
        );
    }
}
