import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { UtilService } from './shared/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  tenantId: string;

  constructor(private utilService: UtilService,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument) {
  }

  ngOnInit() {
    this.utilService.recieveTenantId.subscribe(tenantId => {
      var appFavicon = this._document.getElementById('appFavicon') as HTMLVideoElement;

      if (tenantId.toUpperCase() === 'RPLAZA') {
        this.titleService.setTitle("Real Plaza Go");
        appFavicon.setAttribute('href', 'assets/img/rplaza_favicon_16x16.ico');
      } else if (tenantId.toUpperCase() === 'HPSA') {
        this.titleService.setTitle("Promart");
        appFavicon.setAttribute('href', 'assets/img/hpsa_favicon_16x16.ico');
      } else if (tenantId.toUpperCase() === 'TPSA') {
        this.titleService.setTitle("Oechsle");
        appFavicon.setAttribute('href', 'assets/img/tpsa_favicon_16x16.ico');
      } else if (tenantId.toUpperCase() === 'PVEA') {
        this.titleService.setTitle("plazaVea");
        appFavicon.setAttribute('href', 'assets/img/pvea_favicon_16x16.ico');
      } else if (tenantId.toUpperCase() === 'VIV') {
        this.titleService.setTitle("Vivanda");
        appFavicon.setAttribute('href', 'assets/img/viv_favicon_16x16.ico');
      } else if (tenantId.toUpperCase() === 'AGORA') {
        this.titleService.setTitle("Agora");
        appFavicon.setAttribute('href', 'assets/img/agora_favicon_16x16.ico');
      }

    });
  }
}
